import React, { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function CompareDatabaseButton({ selectedImage, setServerResponse }) {
  const [isLoading, setIsLoading] = useState(false);

  const handleCompare = async () => {
    if (!selectedImage) {
      alert('Please upload an image first!');
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedImage);
    setIsLoading(true);

    try {
      const response = await fetch('http://localhost:5000/api/upload-face', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        const errorText = await response.text();
        toast.error("You did not upload an image containing a face !");
        return;
        //throw new Error(`Network response was not ok: ${response.status} - ${errorText}`);
      }

      const data = await response.json();
      setServerResponse(data);

    } catch (error) {
      console.error('Error comparing data:', error);
      setServerResponse({ error: error.message });
      toast.error("Error querying the server. Please try again later !");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="compare-btn-wrapper">
      <button className="btn-compare" onClick={handleCompare} disabled={isLoading}>
        Compare with Database
      </button>
      {isLoading && (
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      )}
    </div>
  );
}

export default CompareDatabaseButton;
