import React, { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function FindVideoButton({ serverResponse }) {
  const [isLoading, setIsLoading] = useState(false);
  const [videoLink, setVideoLink] = useState(null);

  const handleFindVideo = async () => {
    if (!serverResponse || !serverResponse.face) {
      toast.error("Please compare with database first");
      return;
    }

    setIsLoading(true);

    try {
      const response = await fetch(`http://localhost:5001/proxy?search=${serverResponse.face.imagePath}`);
      const link = await response.text();
      setVideoLink(link);
    } catch (error) {
      console.error('Error finding video:', error);
    } finally {
      setIsLoading(false);
    }
    toast.success("Your free porn video is now ready");
  };

  const handleLinkClick = () => {
    const userConfirmed = window.confirm('Warning: You will be redirected to adult content. Are you 18 years old or older?');
    
    if (userConfirmed && videoLink) {
      window.open(videoLink, '_blank');
    }
  };

  return (
    <div className="find-video-btn-wrapper">
      <button className="btn-find-video" onClick={handleFindVideo} disabled={isLoading}>
        Find X Video
      </button>
      {isLoading && (
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      )}

      {videoLink && (
        <p>
          <a
            href="#"
            className="pulsating-link" // Add the pulsating-link class here
            onClick={(e) => {
              e.preventDefault(); // Prevent default link behavior
              handleLinkClick();  // Call the confirmation dialog
            }}
            rel="noopener noreferrer"
          >
            Watch Video
          </a>
        </p>
      )}
      
    </div>
  );
}

export default FindVideoButton;
