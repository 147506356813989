import React, { useState, useEffect } from 'react';
import './App.css';
import UploadImageButton from './UploadFile';
import CompareDatabaseButton from './CompareToDatabase';
import FindVideoButton from './FindVideo';
import ImageAlert from './ImageAlert';
import logo from './logo3.png';

const path = require('path');

function App() {
  const [serverResponse, setServerResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [actressName, setActressName] = useState('');
  const [actressImage, setActressImage] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageName, setImageName] = useState(null);

  useEffect(() => {
    if (serverResponse) {
      const filenamejpg = serverResponse.face.imagePath.replace(/^.*[\\/]/, '');
      const filename_ = path.parse(filenamejpg).name;
      const filename = filename_.replace(/_/g, ' ');
      setActressName(filename);
      setActressImage(`/images/${filenamejpg}`);
      setShowAlert(true);
    }
  }, [serverResponse]);

  const handleLoading = () => {
    setLoading(true);
  };

  const closeAlert = () => {
    setShowAlert(false);
  };

  return (
    <div className="app-container">
      <div className="header">
        <img src={logo} alt="Logo" className="logo" />
        <div className="title-container">
          <h1>Face Match X</h1>
          <p className="powered-by">Powered by AI</p>
        </div>
      </div>

      <UploadImageButton setSelectedImage={setSelectedImage} setImageName={setImageName} />

      {selectedImage && (
        <CompareDatabaseButton selectedImage={selectedImage} setServerResponse={setServerResponse} />
      )}

      {selectedImage && (
        <FindVideoButton serverResponse={serverResponse} />
      )}

      <footer className="footer">
        <div className="footer-bottom">
          © 2024 Image Comparison Tool. All Rights Reserved.
        </div>
        <div className="footer-links">
          <a href="/about">About</a>
          <a href="/contact">Contact Us</a>
          <a href="/privacy">Privacy Policy</a>
          <a href="/terms">Terms of Service</a>
        </div>
      </footer>

      {showAlert && (
        <ImageAlert
          name={actressName}
          imageSrc={actressImage}
          onClose={closeAlert}
        />
      )}
    </div>
  );
}

export default App;
