import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function UploadImageButton({ setSelectedImage, setImageName }) {
  const [toastShown, setToastShown] = useState(false);

  const handleImageChange = (event) => {
    setToastShown(false);
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
      setImageName(file.name);
      if (!toastShown) {
        toast.success(`The image ${file.name} has been successfully uploaded!`);
        setToastShown(true);
      }
    }
  };

  return (
    <div className="upload-btn-wrapper">
      <label className="btn-upload" htmlFor="upload1">Upload Image</label>
      <input id="upload1" type="file" accept="image/*" onChange={handleImageChange} />
      <ToastContainer />
    </div>
  );
}

export default UploadImageButton;
