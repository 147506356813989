// ImageAlert.js
import React, { useEffect } from 'react';
import './ImageAlert.css'; // Ensure you have this CSS file

const ImageAlert = ({ name, imageSrc, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose(); // Close the alert after 5 seconds
    }, 5000);

    return () => clearTimeout(timer); // Cleanup the timer on unmount
  }, [onClose]);

  return (
    <div className="modal-overlay">
      <div className="modal">
        <p> Most ressembling actress is :</p>
        <h2>{name}</h2>
        <img src={imageSrc} alt={name} className="actress-image" />
        <button onClick={onClose} className="close-btn">&times;</button>
      </div>
    </div>
  );
};

export default ImageAlert;
